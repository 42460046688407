<template>
  <div
    aria-live="polite"
    aria-atomic="true"
    style="position: relative; min-height: 0; z-index: 99999;"
  >
    <div
      id="toast-holder"
      style="position: fixed; bottom: 5px; right: 5px"
      class="pt-2"
    >
      <div
        v-for="(toastNotification, index) in toastNotifications"
        :key="index"
        :ref="showToast"
        class="toast"
        :id="`toast-${index}`"
        role="alert"
        aria-live="assertive"
        aria-delay="true"
        data-delay="4000"
        style="font-size: medium;"
      >
        <div class="toast-header">
          <i
            v-if="toastNotification.status === 'Success'"
            class="fas fa-check mr-2 text-success"
          ></i>
          <i v-else class="fas fa-exclamation-circle mr-2 text-danger"></i>
          <strong class="mr-auto">{{
            $t("common." + toastNotification.status)
          }}</strong>
          <button
            type="button"
            class="ml-2 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
          >
            <span aria-hidden="true" style="float: right;">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          {{ toastNotification.message }}
        </div>
      </div>
    </div>
  </div>
  <IconButton v-show="scrolled" :click="scrollToTop" icon="arrow-up" id="scroll-btn" title="Scroll top" style="display: block;"/>

  <div
    v-if="!loading && $route.name !== 'Meeting'"
    class="wrapper"
    :style="$route.name === 'ViewCourse' ? 'background-color: white' : ''"
  >
    <div :id="user ? 'content' : 'loggedout'">
      <div class="content-padding">
        <div
          v-if="$store.state.offline"
          class="w-100 p-1 text-center text-white mb-2"
          style="background: var(--danger); position: fixed; top: 0; left: 0; z-index: 1000"
        >
          {{ $t("common.no-internet") }}
        </div>
        <div
          v-else-if="showBackOnline"
          class="w-100 p-1 text-center text-white mb-2"
          style="background: var(--success); position: fixed; top: 0; left: 0; z-index: 1000; cursor: pointer;"
          @click="refresh"
        >
          {{ $t("common.back-online") }}
          <i class="fas fa-redo-alt" style="font-size: 14px;"></i>
        </div>
        <Navbar v-if="$route.name != 'Login' && user" />
        <button
          v-show="user && $route.name != 'Chat'"
          class="fab-btn"
          @click.prevent="$router.push({ name: 'Feedback' })"
        >
          <i class="fas fa-comment-dots" style="font-size: 24px;"></i>
        </button>
        <div ref="routerView" class="real-content" style="height: 100%">
          <div class="d-flex align-items-center mb-3"
               v-if="user &&
              $store.state.user.activeTestAttempt &&
              ($store.state.user.activeTestAttempt.courses && $store.state.user.activeTestAttempt.courses.length) &&
              ($route.name != 'ViewCourse' ||
                $route.params.id !=$store.state.user.activeTestAttempt.courses[0].id) &&
              new Date(
                Date.parse(this.$store.state.user.activeTestAttempt.finished_at)
              ) > new Date()
          "
               style="font-size: 24px"
          >
            <i class="fas fa-exclamation-circle mr-2" style="color: red; font-size: 25px"></i>
            <router-link
                :to="{
              name: 'ViewCourse',
              params: { id: $store.state.user.activeTestAttempt.courses[0].id },
            }"
            >
              {{ $t("active-test-attempt.warning") }}
              {{ $store.state.user.activeTestAttempt.timeLeft }}
            </router-link>
          </div>
          <router-view :key="$route.fullPath" />
        </div>
      </div>
      <Footer v-if="$route.name != 'Login' && user" />
    </div>
  </div>
  <div v-else>
    <Loading v-if="loading" />
    <router-view v-else />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import RouteNav from "@/components/RouteNav.vue";
import Loading from "@/components/Loading.vue";
import Footer from "@/components/Footer.vue";
import IconButton from "./components/settings/IconButton.vue";

export default {
  name: "App",
  components: {
    IconButton,
    Navbar,
    RouteNav,
    Footer,
    Loading,
  },
  data() {
    return {
      refreshCount: 0,
      timeout: null,
      scrolled: false,
      showBackOnline: false,
    };
  },
  mounted() {
    window.addEventListener("offline", (event) => {
      this.$store.state.offline = true;
    });

    window.addEventListener("online", (event) => {
      this.$store.state.offline = false;
    });

    window.addEventListener("scroll", (event) => {
      if (window.scrollY > 100) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    });
    $("#sidebar").mCustomScrollbar({
      theme: "minimal",
    });

    $(document).on("click", "#dismiss, .overlay", function() {
      $("#sidebar").removeClass("active");
      $(".overlay").removeClass("active");
    });

    $(document).on("click", ".phone-view", () => {
      $("#sidebar").removeClass("active");
      $(".overlay").removeClass("active");
    });

    $(document).on("click", "#sidebarCollapse", function() {
      $("#sidebar").addClass("active");
      $(".overlay").addClass("active");
      $(".collapse.in").toggleClass("in");
      $("a[aria-expanded=true]").attr("aria-expanded", "false");
    });

    $(document).on("click", "#logout, .overlay", function() {
      $("#sidebar").removeClass("active");
      $(".overlay").removeClass("active");
    });
    $(".toast").toast();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    loading() {
      return this.$store.state.loading;
    },
    toastNotifications() {
      return this.$store.state.toastNotifications;
    },
    offline() {
      return this.$store.state.offline;
    },
  },
  watch: {
    offline(newVal, prevVal) {
      if (prevVal == false && newVal == true) {
        if (this.timeout) clearTimeout(this.timeout);
        this.showBackOnline = true;
        this.timeout = setTimeout(() => (this.showBackOnline = false), 10000);
      }
    },
  },
  methods: {
    showToast(el) {
      try {
        $(el).toast("show");
      } catch (error) {
        window.location.reload();
      }
      
      $(el)
        .off("shown.bs.toast	")
        .on("shown.bs.toast", () => {
          $(el).fadeOut(4000);
        });
      $(el)
        .off("hidden.bs.toast")
        .on("hidden.bs.toast", () => {
          //this.$store.state.toastNotifications.splice(el.id.split("-")[1], 1);
        });
    },
    refresh() {
      this.showBackOnline = false;
      this.refreshCount++;
      if (this.timeout) clearTimeout(this.timeout);
    },
    scrollToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        "slow"
      );
    },
  },
};
</script>
<style>
#loggedout {
  display: flex;
  width: 100%;
  min-height: 100vh;
}

#scroll-btn {
  position: fixed;
  bottom: 60px;
  right: 20px;
  z-index: 9999;
  display: none;
  transition: all 0.15s;
}

@media (max-width: 991px) {
  .mobile-noty {
    position: inherit !important;
  }

  .overlay {
    margin-top: -30px;
    height: 110vh !important;
  }

  #toast-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .toast {
    flex-basis: unset !important;
  }
}

.fab-btn {
  display: none;
}

/* SET CONTENT AREA MARGIN PC VIEW */

@media (min-width: 992px) {
  .real-content {
    margin-top: 76px;
  }

  #loggedout .real-content {
    margin-top: 0px !important;
  }
}
</style>
