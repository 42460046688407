<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="margin-sticky-fix"></div>
    <div class="breadcrumb bg-dark-gray">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="flex-wrap d-flex">
              <div class="d-flex mr-4 pointer">
                <span
                  class="text-16 text-white pt-3 pb-3"
                  @click.prevent="$router.push({ name: 'Home' })"
                  >{{ $t("manage-courses.homepage") }}</span
                >
              </div>
              <div class="d-flex mr-4">
                <span class="text-16 text-white"
                  ><img
                    src="assets/img/icons/bc-seperator.svg"
                    class="h-100"
                    alt=""
                /></span>
              </div>
              <div class="d-flex">
                <span class="text-16 text-white pt-3 pb-3">{{
                  $t("manage-users.manage-users")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- //BREADCRUMB -->
    <div id="content-2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <section id="user-list">
              <div class="row">
                <div class="col-lg-3">
                  <generic-document-download
                    text="Letölés XLSX formátumban"
                    :pdf="false"
                    link="/users/export/xlsx"
                    name="users.xlsx"
                  />
                </div>
                <div class="col-lg-3"></div>
                <div class="col-lg-6 custom-input">
                  <form
                    class="input-group mb-3 modal-search-field"
                    @submit.prevent="startSearch(1)"
                  >
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('chat.user-name')"
                      v-model="searchStr"
                    />

                    <select
                      name="role"
                      v-if="$store.state.user.role != 'Master'"
                      v-model="roleStr"
                      class="role-select"
                    >
                      <option value="" selected>All</option>
                      <option value="user">Users</option>
                      <option value="master">Masters</option>
                      <option value="admin">Admin</option>
                    </select>
                    <button
                      class="input-group-append"
                      :disabled="searching"
                      type="submit"
                    >
                      <span class="input-group-text pt-1 pr-0" id=""
                        ><img src="assets/img/icons/search.svg" alt=""
                      /></span>
                    </button>
                  </form>
                </div>

                <div
                  v-if="$store.state.user.role != 'Master'"
                  class="col-12 row justify-content-between"
                >
                  <div class="col-lg-3">
                    <div class="user-card filter-shadow">
                      <div
                        class="user-header bg-light-gray-secondary text-center bold pb-2"
                      >
                        Registered Users
                      </div>
                      <div class="user-body bg-white">
                        <div class="flex-wrap d-flex mb-2">
                          <div class="m-auto">
                            <div
                              v-if="loadingStatistics"
                              class="ssc-square"
                              style="
                                border-radius: 5px;
                                height: 10px;
                                width: 30px;
                              "
                            ></div>
                            <h2 v-else class="text-light-black">
                              {{ usersStatistics?.registered }}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="user-card filter-shadow">
                      <div
                        class="user-header bg-light-gray-secondary text-center bold pb-2"
                      >
                        Inactive Memberships
                      </div>
                      <div class="user-body bg-white">
                        <div class="flex-wrap d-flex mb-2">
                          <div class="m-auto">
                            <div
                              v-if="loadingStatistics"
                              class="ssc-square"
                              style="
                                border-radius: 5px;
                                height: 10px;
                                width: 30px;
                              "
                            ></div>
                            <h2 v-else class="text-light-black">
                              {{ usersStatistics?.inactiveMembership }}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="user-card filter-shadow">
                      <div
                        class="user-header bg-light-gray-secondary text-center bold pb-2"
                      >
                        Active Memberships
                      </div>
                      <div class="user-body bg-white">
                        <div class="flex-wrap d-flex mb-2">
                          <div class="m-auto">
                            <div
                              v-if="loadingStatistics"
                              class="ssc-square"
                              style="
                                border-radius: 5px;
                                height: 10px;
                                width: 30px;
                              "
                            ></div>
                            <h2 v-else class="text-light-black">
                              {{ usersStatistics?.activeMembership }}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-center w-100">
                  <div>
                    <button
                      title="table view"
                      class="btn mb-2 mr-1"
                      :class="
                        !table_view ? 'btn-outline-info' : 'btn-outline-success'
                      "
                      @click="changeView('table')"
                    >
                      <i class="fas fa-table"></i>
                    </button>
                    <button
                      title="grid view"
                      class="btn mb-2"
                      :class="
                        table_view ? 'btn-outline-info' : 'btn-outline-success'
                      "
                      @click="changeView('grid')"
                    >
                      <i class="fas fa-th-large"></i>
                    </button>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <div v-if="table_view">
                    <table class="table">
                      <thead>
                        <tr
                          class="table-head bg-light-gray-primary text-center"
                        >
                          <th
                            class="text-left pointer align-middle"
                            style="width: 20% !important"
                            @click="orderByFunction('name')"
                          >
                            <strong v-if="orderBy && orderBy == 'name'"
                              >Name
                              <span v-if="orderByDirection == 'asc'">↑</span>
                              <span v-else> ↓</span></strong
                            >
                            <span v-else>Name</span>
                          </th>
                          <th
                            class="pointer align-middle"
                            style="width: 25% !important"
                            @click="orderByFunction('email')"
                          >
                            <strong v-if="orderBy && orderBy == 'email'">
                              Email
                              <span v-if="orderByDirection == 'asc'">↑</span>
                              <span v-else> ↓</span></strong
                            >
                            <span v-else>Email</span>
                          </th>
                          <th
                            v-if="$store.state.user.role != 'Master'"
                            class="pointer align-middle"
                            style="width: 25% !important"
                          >
                            SAP Code
                          </th>
                          <th
                            v-if="$store.state.user.role != 'Master'"
                            class="align-middle"
                            style="width: 20% !important"
                          >
                            Role
                          </th>
                          <th
                            class="pointer align-middle"
                            style="width: 10% !important"
                            @click="orderByFunction('created_at')"
                          >
                            <strong v-if="orderBy && orderBy == 'created_at'"
                              >Created at
                              <span v-if="orderByDirection == 'asc'">↑</span>
                              <span v-else> ↓</span></strong
                            >
                            <span v-else>Created at</span>
                          </th>
                          <th
                            class="align-middle"
                            style="width: 10% !important"
                          >
                            {{ $t("manage-users.ended") }}
                          </th>
                          <th
                            class="align-middle"
                            style="width: 10% !important"
                          >
                            {{ $t("manage-users.ongoing") }}
                          </th>
                          <th
                            class="align-middle"
                            style="width: 10% !important"
                          >
                            {{ $t("manage-users.for-fixing") }}
                          </th>
                          <th
                            class="align-middle"
                            style="width: 10% !important"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="!loading && users">
                        <tr
                          v-for="user in users"
                          :key="user.id"
                          class="text-center border-bottom-grey"
                        >
                          <td class="text-left">
                            <div class="d-flex flex-wrap align-items-center">
                              <div class="d-flex align-items-center pointer">
                                <div class="d-flex">
                                  <span class="text-14">{{ user.name }}</span>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="text-14">
                            {{ user.email }}
                          </td>
                          <td
                            class="text-14"
                            v-if="$store.state.user.role != 'Master'"
                          >
                            {{ user.billing_information?.sap_code }}
                          </td>
                          <td
                            class="text-14"
                            v-if="$store.state.user.role != 'Master'"
                          >
                            <span class="left">{{ user.role }}</span>
                          </td>
                          <td class="text-14">
                            {{
                              user.created_at
                                ? new Date(Date.parse(user.created_at))
                                    .toISOString()
                                    .split("T")[0]
                                : "-"
                            }}
                          </td>
                          <td class="text-14">
                            <div
                              v-if="courseDataLoading"
                              class="ssc-square"
                              style="border-radius: 5px; height: 10px"
                            ></div>
                            <span v-else>{{
                              user.coursesFinished?.length
                            }}</span>
                          </td>
                          <td class="text-14">
                            <div
                              v-if="courseDataLoading"
                              class="ssc-square"
                              style="border-radius: 5px; height: 10px"
                            ></div>
                            <span v-else>{{
                              user.coursesInProgress?.length
                            }}</span>
                          </td>
                          <td class="text-14">
                            <div
                              v-if="courseDataLoading"
                              class="ssc-square"
                              style="border-radius: 5px; height: 10px"
                            ></div>
                            <span v-else>{{
                              user.test_attempts.filter((ta) => {
                                return (
                                  new Date(Date.parse(ta.finished_at)) <
                                  (new Date() && !ta.corrected)
                                );
                              }).length
                            }}</span>
                          </td>
                          <td>
                            <div class="d-flex align-items-center pointer">
                              <span
                                v-if="$store.state.user.role != 'Master'"
                                @click="
                                  $router.push({
                                    name: 'Overview',
                                    params: { id: user.id },
                                  })
                                "
                              >
                                <i class="fas fa-pen"></i>
                              </span>

                              <span
                                class="pointer"
                                v-if="$store.state.user.role != 'Master'"
                                data-toggle="modal"
                                data-target="#deleteUser"
                                @click="openDeleteModal(user.id)"
                              >
                                <i class="fas fa-trash"></i>
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td colspan="7">
                            <loading height="25vh" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else class="row">
                    <!-- USER CARD TYPE #1 -->
                    <div v-for="user in users" :key="user.id" class="col-lg-4">
                      <div class="user-card filter-shadow">
                        <div class="user-header bg-light-gray-secondary">
                          <div class="row">
                            <div class="col-sm-10 col-9">
                              <div class="flex-wrap d-flex">
                                <div class="d-flex mr-auto minus-2">
                                  <div class="image mr-2">
                                    <img
                                      v-if="user.avatar"
                                      :src="
                                        $store.state.storageURL +
                                        user.avatar.path +
                                        '?token=' +
                                        $store.state.token
                                      "
                                      class="img-fluid avatar-rounded white-border mr-2"
                                      alt=""
                                    />
                                    <Avatar
                                      v-else
                                      class="img-fluid avatar-rounded white-border mr-2"
                                      :username="user.name"
                                      :size="46"
                                    />
                                  </div>
                                  <div class="d-flex flex-column">
                                    <h4 class="text-white text-16 bold mb-1">
                                      {{ user.name }}
                                    </h4>
                                    <h5 class="text-white text-10 medium mb-2">
                                      {{ user.email }}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-2 col-3">
                              <div
                                class="d-flex ml-auto float-right"
                                v-if="$store.state.user.role != 'Master'"
                                @click="
                                  $router.push({
                                    name: 'Overview',
                                    params: { id: user.id },
                                  })
                                "
                              >
                                <img
                                  src="assets/img/icons/more-info.svg"
                                  class="img-fluid pointer mt-1 float-right"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="user-body bg-white">
                          <!-- DATA -->
                          <div class="flex-wrap d-flex mb-2">
                            <div class="d-flex">
                              <h4 class="text-14 text-light-black medium">
                                {{ $t("manage-users.ongoing") }}
                              </h4>
                            </div>
                            <div class="ml-auto d-flex">
                              <div
                                v-if="courseDataLoading"
                                class="ssc-square"
                                style="
                                  border-radius: 5px;
                                  height: 10px;
                                  width: 30px;
                                "
                              ></div>
                              <h4
                                v-else
                                class="text-14 text-light-black medium"
                              >
                                {{ user.coursesInProgress?.length }}
                              </h4>
                            </div>
                          </div>
                          <!-- //DATA -->
                          <!-- DATA -->
                          <div class="flex-wrap d-flex mb-2">
                            <div class="d-flex">
                              <h4 class="text-14 text-light-black medium">
                                {{ $t("manage-users.ended") }}
                              </h4>
                            </div>
                            <div class="ml-auto d-flex">
                              <div
                                v-if="courseDataLoading"
                                class="ssc-square"
                                style="
                                  border-radius: 5px;
                                  height: 10px;
                                  width: 30px;
                                "
                              ></div>
                              <h4
                                v-else
                                class="text-14 text-light-black medium"
                              >
                                {{ user.coursesFinished?.length }}
                              </h4>
                            </div>
                          </div>
                          <!-- //DATA -->
                          <!-- DATA -->
                          <div class="flex-wrap d-flex">
                            <div class="d-flex">
                              <h4 class="text-14 text-light-black medium">
                                {{ $t("manage-users.for-fixing") }}
                              </h4>
                            </div>
                            <div class="ml-auto d-flex">
                              <div
                                v-if="courseDataLoading"
                                class="ssc-square"
                                style="
                                  border-radius: 5px;
                                  height: 10px;
                                  width: 30px;
                                "
                              ></div>
                              <h4
                                v-else
                                class="text-14 text-light-black medium"
                              >
                                {{
                                  user.test_attempts.filter((ta) => {
                                    return (
                                      new Date(Date.parse(ta.finished_at)) <
                                      (new Date() && !ta.corrected)
                                    );
                                  }).length
                                }}
                              </h4>
                            </div>
                          </div>
                          <!-- //DATA -->
                        </div>
                      </div>
                    </div>
                    <!-- //USER CARD TYPE #1 -->
                  </div>
                </div>

                <div
                  v-if="paginator && users && users.length > 0"
                  class="row mt-3 w-100"
                >
                  <div class="col-12 d-flex justify-content-center">
                    <custom-paginator
                      :paginator="paginator"
                      @loadPage="paginate"
                      classes="w-100 mb-2"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Confirm login as modal -->
  <ConfirmationModal
    id="deleteUser"
    title="Are you sure?"
    text="You are about to delete this user. please not that this action is permanent."
    @hasEUConsent="deleteUser"
  />
</template>
<script>
import Loading from "@/components/Loading.vue";
import Avatar from "@/components/Avatar.vue";
import CustomPaginator from "../components/CustomPaginator.vue";
import GenericDocumentDownload from "../components/GenericDocumentDownload.vue";
import ConfirmationModal from "../components/ConfirmationModal.vue";
export default {
  name: "ManageUsers",
  components: {
    GenericDocumentDownload,
    Avatar,
    Loading,
    CustomPaginator,
    ConfirmationModal,
  },
  mounted() {
    this.loading = true;

    this.loadingStatistics = true;
    this.axios
      .get("/users")
      .then((resp) => {
        this.users = resp.data.users.data;
        this.paginator = {
          current_page: resp.data.users.current_page,
          last_page: resp.data.users.last_page,
        };
        this.paginator.searchStr = null;
      })
      .finally(() => {
        this.loading = false;
        this.statistics();
        this.fetchUsersCoursesData();
      });
  },
  data() {
    return {
      searchStr: null,
      roleStr: "",
      loading: false,
      courseDataLoading: false,
      loadingStatistics: false,
      table_view: true,
      users: null,
      paginator: null,
      usersStatistics: null,
      orderBy: null,
      orderByDirection: null,
      page: 1,
      perPage: 10,
      userToDelete: null,
    };
  },
  methods: {
    statistics() {
      const searchStr = this.searchStr;
      const roleStr = this.roleStr;
      const params = {
        search: searchStr ? searchStr : null,
        role: roleStr ? roleStr : null,
      };
      this.axios
        .get("/users/usersStatistics", { params })
        .then((resp) => {
          if (this.$store.state.user.role != "Master") {
            this.usersStatistics = resp.data;
          }
        })
        .finally(() => (this.loadingStatistics = false));
    },
    openDeleteModal(id) {
      this.userToDelete = id;
    },
  async  deleteUser() {
      console.log(this.userToDelete);
     await this.axios.delete("/delete-user/" + this.userToDelete)
     .then((resp) => {
      this.users =  this.users.filter(user => user.id !==  this.userToDelete);

      }).catch((e)=>{
        
      });
      this.userToDelete = null;
    },
    changeView(view) {
      if (view == "table") {
        this.table_view = true;
      } else {
        this.table_view = false;
      }
    },
    paginate(page, perPage) {
      this.page = page;
      this.perPage = perPage;
      this.startSearch();
    },
    orderByFunction(column) {
      if (this.orderBy == column) {
        if (this.orderByDirection == "desc") {
          this.orderBy = null;
          this.orderByDirection = null;
          this.startSearch();
          return;
        }
        this.orderByDirection = "desc";
        this.startSearch();
        return;
      }
      this.orderBy = column;
      this.orderByDirection = "asc";
      this.startSearch();
      return;
    },
    startSearch(page = null) {
      this.loading = true;
      const searchStr = this.searchStr;
      const roleStr = this.roleStr;
      const params = {
        search: searchStr ? searchStr : null,
        role: roleStr ? roleStr : null,
        orderBy: this.orderBy,
        orderByDirection: this.orderByDirection,
        page: page ?? this.page,
        perPage: this.perPage,
      };
      this.axios
        .get("/users", { params })
        .then((resp) => {
          this.users = resp.data.users.data;
          this.paginator = {
            current_page: resp.data.users.current_page,
            last_page: resp.data.users.last_page,
          };
          this.paginator.searchStr = searchStr;
        })
        .finally(() => {
          this.loading = false;
          this.statistics();
          this.fetchUsersCoursesData();
        });
    },

    loadUsers() {
      this.loading = true;

      const searchStr = this.paginator.searchStr;
      const params = {
        search: searchStr ? searchStr : null,
        page: 1,
        perPage: this.perPage,
      };
      this.paginator.current_page = 1;
      this.axios
        .get("/users", { params })
        .then((resp) => {
          this.users = resp.data.users.data;
          this.paginator = {
            current_page: resp.data.users.current_page,
            last_page: resp.data.users.last_page,
          };
          this.paginator.searchStr = searchStr;
        })
        .finally(() => {
          this.loading = false;
          this.fetchUsersCoursesData();
        });
    },
    fetchUsersCoursesData() {
      this.courseDataLoading = true;
      if (!this.users.length) {
        return;
      }
      const params = {
        ids: this.users.map((user) => {
          return user.id;
        }),
      };
      this.axios
        .get("/users/coursesInProgressAndFinished", { params })
        .then((resp) => {
          this.users = this.users.map((user) => {
            resp.data.users.forEach((resUser) => {
              if (user.id == resUser.id) {
                user.coursesInProgress = resUser.coursesInProgress;
                user.coursesFinished = resUser.coursesFinished;
                user.test_attempts = resUser.test_attempts;
              }
            });
            return user;
          });
        })
        .finally(() => {
          this.courseDataLoading = false;
        });
    },
  },
};
</script>
<style scoped>
#user-list {
  margin-top: 30px;
}

.user-card {
  border-radius: 5px;
  margin-bottom: 30px;
}

.user-header {
  padding: 10px 10px 0px 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.user-body {
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.minus-2 {
  margin-bottom: -9px;
}

.user-header h4,
.user-header h5 {
  word-break: break-word;
  white-space: normal;
}

.avatar-rounded {
  width: 46px;
  min-height: auto !important;
}

.role-select {
  border: 0px solid black !important;
  height: -webkit-fill-available;
}

.table-responsive .table-responsive {
  background-color: var(--white);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 30px;
}

.table-head {
  color: var(--white) !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.table-head th {
  font-weight: 400;
  font-size: 16px;
}

.fa-pen {
  color: rgba(34, 34, 34, 0.7) !important;
  margin-right: 15px;
}

a:hover {
  text-decoration: none !important;
  color: var(--red);
}

table {
  margin-bottom: 0px !important;
}

table,
table tr,
table th,
table td {
  vertical-align: middle;
}
</style>
